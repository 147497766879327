import { useState } from 'react';
import { Form, Modal, InputNumber } from 'antd';
import { useIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

import './BRSecurityOtpModal.less';

const BRSecurityOtpModal = ({
  title,
  subtitle,
  confirmOTP,
  close,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const intl = useIntl();

  const handleInvalidOTP = ({ isInValid }) => {
    setShowError(isInValid);
  };
  const handleConfirmOTP = async (values) => {
    setIsLoading(true);
    await confirmOTP(values, handleInvalidOTP);
    setIsLoading(false);
  };

  const onValuesChange = () => {
    setShowError(false);
  };

  const onFinishFailed = () => {
    setShowError(false);
  };

  return (
    <Modal
      title={title}
      {...props}
      wrapClassName="br-security_otp-screen"
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
            disabled={isLoading}
          />
          <BRButton
            label={intl.formatMessage({ id: 'common.submit' })}
            htmlType="submit"
            type="primary"
            loading={isLoading}
            form="security-otp-form"
          />
        </>
      }
    >
      <div className="br-security_otp-screen__subtitle">{subtitle}</div>
      <Form
        onFinish={handleConfirmOTP}
        id="security-otp-form"
        onValuesChange={onValuesChange}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          rules={[{ required: true }]}
          name="OTP"
          extra={
            showError ? (
              <span className="ant-form-item-explain-error body">
                {intl.formatMessage({
                  id: 'login.security_otp_screen.invalid_otp'
                })}
              </span>
            ) : null
          }
          hasFeedback
        >
          <InputNumber disabled={isLoading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BRSecurityOtpModal;
