import { isDashboardArabic } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';

export const PRODUCTION_URL = 'https://business.bosta.co/orders?token=';
export const BETA_URL_REDIRECT = 'https://beta-business.bosta.co/orders?token=';
export const BETA_URL_HOSTNAME = 'beta-business.bosta.co';
export const STAGING_URL_HOSTNAME = 'stg-business.bosta.co';
export const PRODUCTION_URL_HOSTNAME = 'business.bosta.co';
export const DEV_URL_HOSTNAME = 'dev-business.bosta.co';
export const ERROR_CODES = {
  USER_NOT_FOUND: 1047,
  NOT_VALID: 1045,
  DELETED_ACCOUNT: 1101,
  PASSWORD_EXPIRED: 4133
};
export const CONSIGNEE_TRACKING_URL_MAPPER = (trackingNumber) => {
  const language = isDashboardArabic() ? LOCALE.AR : LOCALE.EN;

  const mapper = {
    [PRODUCTION_URL_HOSTNAME]: `https://bosta.co/${language}/tracking-shipments?shipment-number=${trackingNumber}`,
    [BETA_URL_HOSTNAME]: `https://bosta.co/${language}/tracking-shipments?shipment-number=${trackingNumber}`,
    [STAGING_URL_HOSTNAME]: `https://stg.bosta.co/${language}/tracking-shipments?shipment-number=${trackingNumber}`,
    localhost: `https://stg.bosta.co/${language}/tracking-shipments?shipment-number=${trackingNumber}`
  };

  return mapper[window.location.hostname] || mapper[PRODUCTION_URL_HOSTNAME];
};

export const PAYMENT_REDIRECT_URL = () => {
  const mapper = {
    [PRODUCTION_URL_HOSTNAME]: `https://business.bosta.co/payment`,
    [BETA_URL_HOSTNAME]: `https://beta-business.bosta.co/payment`,
    [STAGING_URL_HOSTNAME]: `https://stg-business.bosta.co/payment`,
    [DEV_URL_HOSTNAME]: `https://dev-business.bosta.co/payment`,
    localhost: `https://stg-business.bosta.co/payment`
  };

  return mapper[window.location.hostname] || mapper[PRODUCTION_URL_HOSTNAME];
};

export const TWO_FACTOR_AUTH_ENABLED_STATUS_CODE = 206;

export const SECURITY_TYPES = {
  SMS: 'SMS_TWO_FA',
  APP: 'TOTP_TWO_FA',
  EMAIL: 'EMAIL_TWO_FA'
};
