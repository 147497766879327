import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import classnames from 'classnames';
import { CaretUpOutlined } from '@ant-design/icons';

import { COUNTRY_POINT_MAP } from 'constants/pickups';
import { MAP_MARKER_STATUS } from 'constants/brGoogleMap';
import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';

import { notify } from 'components/Notify/Notify';
import PlacesSearchBox from 'components/BRGoogleMap/PlacesSearchBox/PlacesSearchBox';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import MarkerIconInitial from 'assets/bosta-icons/google-map-marker-initial.svg';
import MarkerIconLoading from 'assets/bosta-icons/google-map-marker-loading.svg';
import MarkerIconSuccess from 'assets/bosta-icons/google-map-marker-success.svg';
import MarkerIconUndetermined from 'assets/bosta-icons/google-map-marker-undetermined.svg';
import MarkerIconFailed from 'assets/bosta-icons/google-map-marker-failed.svg';

import './BRGoogleMap.less';

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
};

const geocodeApiKey =
  window.env && window.env.GOOGLE_MAP_API_KEY
    ? window.env.GOOGLE_MAP_API_KEY
    : '';

class BRGoogleMap extends Component {
  state = {
    address: '',
    city: '',
    area: '',
    state: '',
    streetName: '',
    buildingNumber: '',
    mapPosition: {},
    isOpenedForFirstTime: false,
    isLoading: false,
    zoomLevel: 17
  };

  refMap = React.createRef('');

  getStreetName = (addressArray) => {
    let streetName = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
        streetName = addressArray[i].long_name;
        return streetName;
      }
    }
  };

  getBuildingNumber = (addressArray) => {
    let buildingNumber = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'street_number' === addressArray[i].types[0]
      ) {
        buildingNumber = addressArray[i].long_name;
        return buildingNumber;
      }
    }
  };

  getFormattedAddress = async (lat, lng, detectedAddress) => {
    const { setCurrentAddress, setCurrentPosition } = this.props;

    try {
      if (window.google) {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({
          location: {
            lat,
            lng
          }
        });

        if (response) {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            streetName = this.getStreetName(addressArray) || address,
            buildingNumber = this.getBuildingNumber(addressArray);
          this.setState(
            {
              address: address ? address : '',
              buildingNumber: buildingNumber ? buildingNumber : '',
              streetName: streetName ? streetName : ''
            },
            () => {
              if (setCurrentAddress) {
                setCurrentAddress({
                  address,
                  streetName,
                  buildingNumber,
                  lat,
                  lng
                });
                if (detectedAddress) {
                  setCurrentPosition({ lat, lng });
                }
              }
            }
          );
        }
      }
    } catch (error) {
      // notify({ msg: error.message, ...error });
    }
  };

  setEnteredPosition = ({ lat, lng }) => {
    const { setCurrentPosition } = this.props;
    this.setState({
      mapPosition: { lat, lng },
      isOpenedForFirstTime: false
    });
    setCurrentPosition({ lat, lng });
  };

  getCurrentPosition = () => {
    this.setState({ isLoading: true });
    const { handleConfirmLocation = () => {} } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position?.coords || {};
          this.setEnteredPosition({
            lat: latitude,
            lng: longitude
          });
          this.setState(
            {
              mapPosition: {
                lat: latitude,
                lng: longitude
              },
              zoomLevel: 17
            },
            () => {
              handleConfirmLocation();
              this.getFormattedAddress(
                this.state.mapPosition.lat,
                this.state.mapPosition.lng,
                true
              );
            }
          );
        },
        () => {
          this.handleLocationError(false);
        },
        { maximumAge: 6000, timeout: 4000, enableHighAccuracy: true }
      );
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false);
    }
    this.setState({ isLoading: false });
    this.setState({ zoomLevel: 17 });
  };

  handleLocationError = (browserHasGeolocation) => {
    const { intl } = this.props;
    notify({
      msg: browserHasGeolocation
        ? intl.formatMessage({
            id: 'br_google_map.service_failed'
          })
        : intl.formatMessage({
            id: 'br_google_map.not_supported_geolocation'
          })
    });
  };

  setSearchResultAddress = (searchResult) => {
    const { setCurrentAddress } = this.props;
    const address = searchResult.formatted_address,
      addressArray = searchResult.address_components,
      streetName = this.getStreetName(addressArray),
      buildingNumber = this.getBuildingNumber(addressArray);
    this.setState(
      {
        address: address ? address : '',
        streetName: streetName ? streetName : '',
        buildingNumber: buildingNumber ? buildingNumber : ''
      },
      () => {
        if (setCurrentAddress) {
          setCurrentAddress({ address, streetName, buildingNumber });
        }
      }
    );
  };

  preventGoogleAPIFontsLoading = () => {
    //This for perfoemance to load page faster
    const head = document.getElementsByTagName('head')[0];
    const insertBefore = head.insertBefore;
    head.insertBefore = function (newElement, referenceElement) {
      if (
        newElement.href &&
        newElement.href.indexOf(
          'https://fonts.googleapis.com/css?family=Roboto'
        ) === 0
      ) {
        return;
      }
      insertBefore.call(head, newElement, referenceElement);
    };
  };

  componentDidMount() {
    this.preventGoogleAPIFontsLoading();
    const { isMapUsed, userPosition, setCurrentPosition } = this.props;
    this.setState({ isOpenedForFirstTime: true });
    if (userPosition && isMapUsed) {
      const { lat, lng } = userPosition;
      this.setState({ mapPosition: userPosition });
      setCurrentPosition && setCurrentPosition({ lat, lng });
      this.getFormattedAddress(lat, lng);
    }
  }

  handleOnDragMapEnd = (e, data) => {
    const { center } = data;
    const { setCurrentPosition, handleConfirmLocation } = this.props;
    const newLat = center.lat();
    const newLng = center.lng();
    this.setState({
      mapPosition: { lat: newLat, lng: newLng }
    });
    setCurrentPosition && setCurrentPosition({ lat: newLat, lng: newLng });
    this.getFormattedAddress(newLat, newLng);
    handleConfirmLocation();
  };

  handleOnDragMapStart = () => {
    const { handleNewSearchInput } = this.props;
    handleNewSearchInput();
    this.handleIsOpenedForTheFirstTime(false);
  };

  handleZoomChanged = () => {
    const { zoomLevel } = this.state;
    const currentZoomLevel = this.refMap.current.map.getZoom();
    if (currentZoomLevel !== zoomLevel) {
      this.setState({ zoomLevel: currentZoomLevel });
    }
    this.handleIsOpenedForTheFirstTime(false);
  };

  onMarkerDragStart = (coord) => {
    const { handleNewSearchInput } = this.props;
    handleNewSearchInput();
    this.handleIsOpenedForTheFirstTime(false);
  };

  onMarkerDragEnd = (coord) => {
    const { handleNewSearchInput, handleConfirmLocation } = this.props;
    handleNewSearchInput();
    const { setCurrentPosition } = this.props;
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({
      mapPosition: { lat, lng },
      isOpenedForFirstTime: false
    });
    setCurrentPosition && setCurrentPosition({ lat, lng });
    this.getFormattedAddress(lat, lng);
    handleConfirmLocation();
  };

  handleIsOpenedForTheFirstTime = (state) => {
    this.setState({ isOpenedForFirstTime: state });
  };

  handleMarkerUrl = () => {
    const { markerStatus } = this.props;
    const { INITIAL, LOADING, SUCCESS, FAILED } = MAP_MARKER_STATUS;
    switch (markerStatus) {
      case INITIAL:
        return MarkerIconInitial;
      case LOADING:
        return MarkerIconLoading;
      case SUCCESS:
        return MarkerIconSuccess;
      case FAILED:
        return MarkerIconFailed;
      default:
        return MarkerIconUndetermined;
    }
  };

  render() {
    const {
      className,
      withSearch,
      handleNewSearchInput,
      intl,
      google,
      customNoteRender = false,
      markerStatus,
      handleConfirmLocation,
      close,
      shouldDetectUserLocationOnMount = false
    } = this.props;

    const { isOpenedForFirstTime, isLoading, mapPosition, zoomLevel } =
      this.state;

    const { FAILED, UNDETERMINED } = MAP_MARKER_STATUS;
    return (
      <LoadingWrapper loading={isLoading}>
        <div
          className={classnames('br-google-map', className, {
            'br-marker-error': [FAILED, UNDETERMINED].includes(markerStatus)
          })}
        >
          {withSearch &&
            isOpenedForFirstTime &&
            (customNoteRender ? (
              customNoteRender()
            ) : (
              <div className="br-google-map__marker__note">
                <CaretUpOutlined />
                <div>
                  <p className="br-google-map__marker__note__title br-google-map__marker__note__text body-medium">
                    {intl.formatMessage({
                      id: 'br_google_map.move_map_note_title'
                    })}
                  </p>
                  <p className="br-google-map__marker__note__description br-google-map__marker__note__text  body">
                    {intl.formatMessage({
                      id: 'br_google_map.move_map_note_description'
                    })}
                  </p>
                </div>
              </div>
            ))}
          {markerStatus === UNDETERMINED && (
            <div className="br-google-map__marker__error__message br-google-map__marker__error__undetermined">
              <p className="body">
                {intl.formatMessage({
                  id: 'br_google_map.actions.position_error_message'
                })}
                <a onClick={close}>
                  {' '}
                  {intl.formatMessage({
                    id: 'br_google_map.actions.add_manually'
                  })}
                </a>
              </p>
            </div>
          )}
          {markerStatus === FAILED && (
            <div className="br-google-map__marker__error__message br-google-map__marker__error__failed">
              <p className="body">
                {intl.formatMessage({
                  id: 'br_google_map.actions.uncovered_zone_message'
                })}
              </p>
            </div>
          )}
          <Map
            google={google}
            center={mapPosition}
            initialCenter={COUNTRY_POINT_MAP}
            zoom={zoomLevel}
            onZoomChanged={this.handleZoomChanged}
            draggable={withSearch ? true : false}
            onDragstart={this.handleOnDragMapStart}
            onDragend={this.handleOnDragMapEnd}
            containerStyle={containerStyle}
            ref={this.refMap}
          >
            {withSearch && (
              <Marker
                position={mapPosition.lat ? mapPosition : COUNTRY_POINT_MAP}
                draggable={withSearch}
                onDragstart={this.onMarkerDragStart}
                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                icon={{
                  url: this.handleMarkerUrl()
                }}
              />
            )}
            {withSearch && (
              <PlacesSearchBox
                setEnteredPosition={this.setEnteredPosition}
                setSearchResultAddress={this.setSearchResultAddress}
                userMoveMap={this.getCurrentPosition}
                handleNewSearchInput={handleNewSearchInput}
                handleIsOpenedForTheFirstTime={
                  this.handleIsOpenedForTheFirstTime
                }
                handleConfirmLocation={handleConfirmLocation}
                markerStatus={markerStatus}
                shouldDetectUserLocationOnMount={
                  shouldDetectUserLocationOnMount
                }
              />
            )}
          </Map>
        </div>
      </LoadingWrapper>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: geocodeApiKey,
  LoadingContainer: () => <LoadingWrapper />,
  region: [getCurrentUserCountryData().codeName]
})(injectIntl(BRGoogleMap));
