import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import { mediaHook } from 'customHooks';
import classNames from 'classnames';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { fetchCities } from 'services/cities';
import { createPickupLocation } from 'services/pickups';
import { editPickupLocation } from 'services/pickup-locations';
import { updateGetStartBusiness } from 'services/auth';
import { sendSegment } from 'utils/segment';
import { COUNTRIES } from 'constants/country-data';
import { addCountryCodeNumber } from 'utils/helpers';
import { getInternationalShippingDestinationCountries } from 'constants/countries/countries-mapping';

import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import ContactForm from 'components/Pickups/components/CreatePickupLocationFormModal/components/ContactForm/ContactForm';
import LocationForm from 'components/Pickups/components/CreatePickupLocationFormModal/components/LocationForm/LocationForm';

import './CreatePickupLocationFormModal.less';

const userInfo = JSON.parse(localStorage?.getItem('userInfo'));

class CreatePickupLocationFormModal extends React.Component {
  state = {
    locationData: [],
    contactsFormData: [],
    showLocation: false,
    showContacts: false,
    cities: [],
    isFormLoading: false,
    updatedLocation: false,
    cityID: '',
    allAreas: []
  };
  formRef = React.createRef();

  handleSetAllAreas = (allAreas) => {
    this.setState({ allAreas });
  };

  getCities = async () => {
    try {
      const { list } = await fetchCities({ context: 'pickup' });
      this.setState({ cities: list });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  handleChangeCityID = (cityID) => {
    this.setState({ cityID });
  };

  componentDidMount() {
    const { pickupLocation } = this.props;
    this.setState({
      showLocation: true
    });
    this.getCities();
    if (pickupLocation) {
      this.setState(
        {
          locationData: pickupLocation,
          showContacts: false,
          updatedLocation: true
        },
        () => {
          this.formRef.current.setFieldsValue({
            locationName: pickupLocation?.locationName,
            city: pickupLocation?.address?.city?._id,
            districtId: pickupLocation?.address?.district?._id,
            buildingNumber: pickupLocation?.address?.buildingNumber,
            firstLine: pickupLocation?.address?.firstLine,
            floor: pickupLocation?.address?.floor,
            apartment: pickupLocation?.address?.apartment,
            secondLine: pickupLocation?.address?.secondLine,
            default: pickupLocation?.default
          });
        }
      );
    }
    sendSegment('E_PICKUP_LOCATION_CREATE');
  }

  handleBackClick = () => {
    const { locationData } = this.state;

    this.setState({ showLocation: true, showContacts: false }, () => {
      this.formRef.current.setFieldsValue({
        locationName: locationData.locationName,
        city: locationData.city,
        districtId: locationData.districtId,
        buildingNumber: locationData.buildingNumber,
        firstLine: locationData.firstLine,
        floor: locationData.floor,
        apartment: locationData.apartment,
        secondLine: locationData.secondLine,
        default: locationData.default
      });
    });
    sendSegment('E_PICKUP_NEW_LOCATION_CONTACT_BACK');
  };

  handleSubmit = async (values) => {
    const { locationData, updatedLocation, cityID } = this.state;
    const {
      onSuccess,
      close,
      intl,
      pickupLocation,
      initialCountryId,
      isCreatingInternationalLocation
    } = this.props;
    const isSettingFirstPickupLocation =
      !userInfo?.user?.businessAdminInfo?.hasAddress;
    const locationName = locationData.locationName;
    const defaultLocation = isSettingFirstPickupLocation
      ? true
      : locationData.default;
    delete locationData.locationName;
    if (locationData?.districtId) {
      delete locationData.city;
    }

    if (initialCountryId) {
      const countriesList = isCreatingInternationalLocation
        ? getInternationalShippingDestinationCountries()
        : COUNTRIES;
      const country = countriesList.find(
        (country) => country.id === initialCountryId
      );
      values.phone = addCountryCodeNumber({
        phoneNumber: values.phone,
        country
      });
    }

    delete locationData.default;
    delete locationData.selectedCountryId;

    this.setState({ isFormLoading: true });

    let payload = {
      locationName: locationName,

      address: {
        ...locationData
      },
      default: defaultLocation
    };
    try {
      if (updatedLocation) {
        const newPickupLocationContacts = pickupLocation.contacts.map(
          (item, index) => {
            if (index === 0 && item.isDefault === undefined) {
              return { ...item, isDefault: true };
            } else if (item.isDefault === undefined) {
              return { ...item, isDefault: false };
            } else {
              return item;
            }
          }
        );

        payload = {
          ...payload,
          contacts: newPickupLocationContacts
        };

        await editPickupLocation({
          pickupLocationId: pickupLocation._id,
          payload
        });

        notify({
          msg: intl.formatMessage({
            id: `settings.pickup_locations.pickup_location_form.update_msg`
          }),
          type: 'success'
        });
        onSuccess();
      } else {
        payload = {
          ...payload,
          contacts: [
            {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              secPhone: values.secPhone,
              isDefault: true
            }
          ]
        };

        sendSegment('E_PICKUP_NEW_LOCATION_CREATED', {
          'is default': locationData.default ? 'Yes' : 'No'
        });

        const businessId = userInfo?.user?.businessAdminInfo?.businessId;

        const { pickupId } = await (isSettingFirstPickupLocation
          ? updateGetStartBusiness(
              { pickupAddress: [{ ...payload.address, locationName }] },
              businessId
            )
          : createPickupLocation(payload));

        userInfo.user.businessAdminInfo = {
          ...userInfo.user.businessAdminInfo,
          defaultPickupLocation: {
            locationName: locationName,
            address: {
              district: {
                _id: values.districtId
              },
              city: {
                _id: cityID
              },
              firstLine: values.firstLine,
              buildingNumber: values.buildingNumber,
              floor: values.floor,
              apartment: values.apartment,
              secondLine: values.secondLine
            }
          },
          hasAddress: true
        };

        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        notify({
          msg: intl.formatMessage({
            id: `settings.pickup_locations.pickup_location_form.create_pickup_location_success`
          }),
          type: 'success'
        });
        onSuccess(pickupId);
      }
      close();
    } catch (error) {
      notify({ msg: error.message, error });
      sendSegment(
        'E_PICKUP_NEW_LOCATION_CREATED',
        {
          'Errors list': error.message
        },
        'Failed'
      );
    }

    this.setState({ isFormLoading: false });
  };

  onFinishFailed = (errorInfo) => {
    let errorsArray = [];
    errorInfo?.errorFields.map((error) => {
      errorsArray.push(error.errors);
    });
    sendSegment(
      'E_PICKUP_NEW_LOCATION_CREATED',
      {
        'Validation Errors': errorsArray.join(',')
      },
      'Failed'
    );
  };

  handleNextToModalLocation = (values) => {
    const { updatedLocation } = this.state;
    if (updatedLocation) {
      this.setLocationData(null, omitBy(values, isNil));
      this.handleSubmit(omitBy(values, isNil));
    } else {
      this.setState({
        showLocation: false,
        showContacts: true,
        locationData: values
      });
    }
    sendSegment('E_PICKUP_NEW_LOCATION_NEXT');
  };

  setContactsFormData = (changedFields, allValues) => {
    this.setState({ contactsFormData: allValues });
  };

  setLocationData = (changedFields, allValues) => {
    this.setState({ locationData: allValues });
  };

  render() {
    const {
      close,
      intl,
      pickupLocation,
      onCancel,
      subTitle = '',
      mobileScreenSizes,
      initialCountryId,
      isCreatingInternationalLocation
    } = this.props;
    const {
      showLocation,
      showContacts,
      cities,
      isFormLoading,
      cityID,
      locationData,
      allAreas
    } = this.state;

    const { isSmallMobileScreen } = mobileScreenSizes;

    return (
      <Modal
        {...this.props}
        className="br-location-contact-modal"
        title={null}
        footer={null}
        onCancel={close}
        width={null}
      >
        <BRContentHeader
          title={intl.formatMessage({
            id: pickupLocation
              ? 'settings.pickup_locations.pickup_location_form.update_pickup_location'
              : showLocation
              ? 'settings.pickup_locations.pickup_location_form.pickup_location_title'
              : 'settings.pickup_locations.pickup_location_form.contact_person_title'
          })}
          subtitle={
            pickupLocation || subTitle
              ? ''
              : intl.formatMessage({
                  id: showLocation
                    ? 'settings.pickup_locations.pickup_location_form.pickup_location_sub_title'
                    : 'settings.pickup_locations.pickup_location_form.contact_person_sub_title'
                })
          }
          titleClassName="br-location-contact-form-header__title display-xs"
          subTitleClassName="br-location-contact-form-header__subtitle"
          className={classNames('br-location-contact-form-header', {
            'br-location-contact-form-header-with-back': showContacts
          })}
          withBackButton={showContacts}
          onBackButtonClick={this.handleBackClick}
        />
        {showLocation && (
          <LocationForm
            handleNextToModalLocation={this.handleNextToModalLocation}
            handleLocationFormCancel={this.handleLocationFormCancel}
            setFieldsValueState={this.setFieldsValue}
            cities={cities}
            formRef={this.formRef}
            close={() => {
              close();
              if (onCancel) onCancel();
            }}
            isFormLoading={isFormLoading}
            pickupLocation={pickupLocation}
            onFinishFailed={this.onFinishFailed}
            handleChangeCityID={this.handleChangeCityID}
            cityID={cityID}
            initialCountryId={initialCountryId}
            isCreatingInternationalLocation={isCreatingInternationalLocation}
            setAreas={this.handleSetAllAreas}
            areas={allAreas}
            hideDefaultSetCheckbox={
              !userInfo?.user?.businessAdminInfo?.hasAddress ||
              isCreatingInternationalLocation
            }
          />
        )}
        {showContacts && (
          <ContactForm
            handleSubmit={this.handleSubmit}
            setFieldsValueState={this.setContactsFormData}
            close={close}
            contactsFormData
            handleBackClick={this.handleBackClick}
            onFinishFailed={this.onFinishFailed}
            selectedCountryId={initialCountryId}
          />
        )}
      </Modal>
    );
  }
}

export default mediaHook(injectIntl(CreatePickupLocationFormModal));
