import { isIOS, isMobile } from 'react-device-detect';

import { phoneNumberRule } from 'utils/forms';
import { DEVICE_ID_KEY } from 'constants/common';

import { notify } from 'components/Notify/Notify';

export const overviewColumns = [
  {
    dataField: 'trackingNumber',
    text: 'Tracking Number'
  },
  {
    dataField: 'customer_info',
    text: 'Customer Info'
  },
  {
    dataField: 'location',
    text: 'Location'
  },
  {
    dataField: 'reason',
    text: 'Reason'
  },
  {
    dataField: 'held_for',
    text: 'Held For'
  }
];

export const phoneRegExp = /^(\+2)?(?=\d{10,11}$)0[128]\d{9}$/;
export const saudiInternationalPhoneRegExp =
  /^\+(?:[\d -]*)|(\+9665|05|5)([013-9][0-9]{7})$/;
export const trackingNumberRegExp = /^\d{6,8}$/;

export const downloadAsCSV = (text, fileName) => {
  const pom = document.createElement('a');
  const csvContent = text; // here we load our csv data
  const blob = new Blob([csvContent], {
    type: 'text/csv;charset=utf-8;'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
};

/**
 * download excel file
 * @param text
 * @param fileName
 */
export const downloadAsXlsx = (text, fileName) => {
  const pom = document.createElement('a');
  const xlsxContent = text; // here we load our csv data
  const blob = new Blob([xlsxContent], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
};

export const logout = (pathname = null) => {
  const locale = localStorage.getItem('Locale');
  const showVerifyEmailBanner = localStorage.getItem('showVerifyEmailBanner');

  const teamsAnnouncementShown = localStorage.getItem('TeamsAnnouncementShown');
  const deviceId = localStorage.getItem(DEVICE_ID_KEY);

  localStorage.removeItem('userInfo');
  localStorage.removeItem('newToken');
  localStorage.removeItem('personId');
  localStorage.removeItem('dealId');
  localStorage.removeItem('orgId');

  navigator.serviceWorker &&
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  localStorage.clear();

  if (pathname != null) {
    pathname();
  } else {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  localStorage.setItem('Locale', locale ? locale : 'en');
  localStorage.setItem('showVerifyEmailBanner', showVerifyEmailBanner);
  localStorage.setItem('TeamsAnnouncementShown', teamsAnnouncementShown);
  if (deviceId && deviceId !== 'null') {
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
};

export const formatErrorMessage = (listOfAllErrors) => {
  return listOfAllErrors.map((error) => error.message).join(' -- ');
};

export const handlingErrorMessage = (err, defaultMsg, stopLoading, type) => {
  const invalidkey = 'Invalid authorization token or API key.';
  stopLoading && stopLoading();
  let errMsg = '';
  if (err?.response && typeof err.response.data === 'string') {
    errMsg = err.response.data;
  } else if (err?.response?.data?.error?.details?.length > 0) {
    errMsg = err.response.data.error.details.reduce(
      (cur, acc) => `${cur ? `${cur}, ` : cur} ${acc.message}`,
      ''
    );
  } else if (err?.response?.data?.errors?.length > 0) {
    errMsg = err.response.data.errors.reduce(
      (cur, acc) => `${cur ? `${cur}, ` : cur} ${acc.message}`,
      ''
    );
  } else if (Array.isArray(err?.response?.data)) {
    errMsg = err.response.data.reduce(
      (cur, acc) => `${cur ? `${cur}, ` : cur} ${acc.message}`,
      ''
    );
  }
  const errorMessage =
    errMsg ||
    err?.response?.data?.description ||
    err?.response?.data?.message ||
    defaultMsg;

  notify({ msg: errorMessage, type });

  if (
    errMsg === invalidkey ||
    err?.response?.data?.description === invalidkey ||
    err?.response?.data?.message === invalidkey ||
    defaultMsg === invalidkey
  ) {
    logout();
  }
  return errorMessage;
};

export const moneyFormatter = (val) =>
  new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'egp',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(val);

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            window.location.reload();
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const validatePhoneNumber = async ({
  value,
  required,
  phoneNumberRuleProps
}) => {
  if (required && !value) {
    return false;
  }

  try {
    const data = await phoneNumberRule({ ...phoneNumberRuleProps }).validator(
      undefined,
      value
    );

    if (data) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export function arrayMove(arr, fromIndex, toIndex) {
  while (fromIndex < 0) {
    fromIndex += arr.length;
  }
  while (toIndex < 0) {
    toIndex += arr.length;
  }
  if (toIndex >= arr.length) {
    let k = toIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
  return arr;
}

export const scrollToTop = () => {
  document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });
};

export const getDeviceType = () => {
  if (isMobile) {
    return isIOS ? 'Web_iPhone' : 'Web_Android';
  }

  return 'Web_Desktop';
};
